import { useEffect, useState } from "react";

import Link from "next/link";
import { useRouter } from "next/router";

import Loader from "@/components/Loader";
import useTranslation from "@/hooks/useTranslation";
import { UserType } from "@/lib/auth";
import { genericBox } from "@/styles/Blocks.module.scss";

export default function Home({ user, studio, performers, errors = {}, token, hasOtherModels }) {
	const { replace, query, isReady } = useRouter();
	const [showTestAccounts, setShowTestAccounts] = useState(false);
	const { _ } = useTranslation();
	const hasErrors = Object.keys(errors).length;

	useEffect(() => {
		if (query?.showTestAccounts) {
			setShowTestAccounts(!!JSON.parse(query?.showTestAccounts));
		}
	}, [query]);

	useEffect(() => {
		(async () => {
			if (query?.token && token && query?.token !== token) {
				await replace(`/logout?redirect=/?token=${query.token}`);
				return;
			}

			if (user?.type === UserType.STUDIO) {
				if ((hasErrors === 1 && !performers?.length) || !(hasErrors || hasOtherModels)) {
					await replace(`/erasure/account-selection/owner`);
				} else {
					await replace("/erasure/");
				}
			} else if (user?.type === UserType.MODEL) {
				await replace("/erasure/account-selection/model");
			} else if (user?.type === UserType.SINGLE) {
				await replace(studio?.hasPayableBalance ? "/erasure/payout/" : "/erasure/privacy-rights");
			}
		})();
	}, [replace, studio?.hasPayableBalance, user, token, performers, errors, query?.token]);

	if (showTestAccounts) {
		return (
			<div className={genericBox}>
				<h1>Test accounts</h1>
				<p>With test data below</p>
				<p>
					<Link href="/?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NjU3Mjg1MDUsImV4cCI6MTY5NzI2NDUwNSwiVXNlcklEIjoxMjM0NTYsIlVzZXJFbWFpbCI6InRlc3RAdXNlci5lbWFpbCJ9.ysZ7sQrITNEvpweIXd3HjJG9Tqn508LxD0FaBeC4qhI&fake=true">
						Single account
					</Link>
					<br />
					<Link href="/?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NjU3Mjg1MDIsImV4cCI6MTY5NzI2NDUwMiwiVXNlcklEIjoxMjM0NTYsIlVzZXJFbWFpbCI6InRlc3RAdXNlci5lbWFpbCJ9.FA0PFyGYzdJnYiWs1bRA2p44VGyW7smZVDX_BqHkXjc&fake=true">
						Model account
					</Link>
					<br />
					<Link href="/?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NjU3Mjg0OTcsImV4cCI6MTY5NzI2NDQ5NywiVXNlcklEIjoxMjM0NTYsIlVzZXJFbWFpbCI6InRlc3RAdXNlci5lbWFpbCJ9.yLU_KGwsjYWNe5KDyeMAz9FLcujsIEIpDn_DSy9e8Fg&fake=true">
						Studio account
					</Link>
				</p>
			</div>
		);
	}
	if (!isReady || query?.token) {
		return <Loader />;
	}

	return (
		<div className={genericBox}>
			<h1>
				{_(
					"This site is operated by JWS\u00A0Americas\u00A0S.à\u00A0r.l. and JWS\u00A0International\u00A0S.à\u00A0r.l."
				)}
			</h1>
			<p>
				JWS&nbsp;Americas&nbsp;S.à&nbsp;r.l. and JWS&nbsp;International&nbsp;S.à&nbsp;r.l. 44, Avenue John F. Kennedy,
				L-1855 Luxembourg, Grand Duchy of Luxembourg
			</p>
			<p>
				Email:{" "}
				<a href="&#109;&#097;&#105;&#108;&#116;&#111;&#058;&#115;&#117;&#112;&#112;&#111;&#114;&#116;&#064;&#106;&#119;&#115;&#112;&#114;&#105;&#118;&#097;&#099;&#121;&#046;&#099;&#111;&#109;">
					&#115;&#117;&#112;&#112;&#111;&#114;&#116;&#064;&#106;&#119;&#115;&#112;&#114;&#105;&#118;&#097;&#099;&#121;&#046;&#099;&#111;&#109;
				</a>
			</p>
		</div>
	);
}

Home.breadcrumbs = false;
